<div class='p-fluid'>
  <span class="p-float-label">
    <input
      type="password"
      pInputText
      [(ngModel)]="password"
      [ngClass]="{'p-invalid': invalid}"
      (keyup.enter)="onPasswordConfirmDebounced()"
      appAutoFocus
      autocomplete="current-password"
    />
    <label>Password</label>
  </span>
  <small class='p-invalid p-error' *ngIf='invalid'>{{ errorMessage }}</small>
</div>

<div class='p-fluid'>
  <app-suspense-button [loading]='loading' label='Confirm' (buttonClick)='onPasswordConfirmDebounced()'></app-suspense-button>
  <p-button
    label="Forgot your password?"
    (onClick)="onForgotPassword()"
    styleClass="p-button-secondary p-button-outlined mt-3"
    [disabled]="forgotPasswordButtonDisabled"
  ></p-button>
</div>
