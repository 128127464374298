<p-toolbar styleClass="p-0 pt-3 pb-3">
  <div class="p-toolbar-group-start flex-1">
    <h1 class="m-0">Users {{ companyName ? 'of ' + companyName : '' }}</h1>
  </div>

  <div class="p-toolbar-group-end button-container">
    <button
      pButton
      icon="pi pi-user"
      label="User Sessions"
      [routerLink]="['user-sessions']"
      [appRequiresRoleToSee]="authorisedRolesByAction.manageUserSessions"
    ></button>
    <button pButton icon="pi pi-plus" label="Create User" [routerLink]="['new']" [appRequiresRoleToSee]="authorisedRolesByAction.createUser"></button>
    <button
      pButton
      icon="pi pi-money-bill"
      label="Manage Billing"
      class="p-button-secondary button-secondary"
      [routerLink]="['billing']"
      [appRequiresRoleToSee]="authorisedRolesByAction.manageBilling"
    ></button>
    <button
      pButton
      icon="pi pi-id-card"
      label="Create Site Admin"
      class="p-button-danger button-danger"
      [routerLink]="['createSiteAdmin']"
      [appRequiresRoleToSee]="authorisedRolesByAction.createSiteAdmin"
    ></button>
    <button
      pButton
      icon="pi pi-id-card"
      label="Create E-Support Account"
      class="p-button-danger button-danger"
      [routerLink]="['createESupport']"
      [disabled]="true"
      *ngIf="shouldDisplay()"
    ></button>
  </div>
</p-toolbar>
