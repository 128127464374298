// @ts-nocheck

import { Component, OnInit } from '@angular/core';
import { AuthProviderService } from '../../../../-services-/auth-framework/auth-provider.service';
import { extractErrorText } from '../../../../utilities/extract-error-text';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-force-password-change-form',
  templateUrl: './force-password-change-form.component.html',
  styleUrls: ['./force-password-change-form.component.css']
})
export class ForcePasswordChangeFormComponent implements OnInit {
  // input models
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;

  // states
  isLoading = false;

  constructor(private authService: AuthProviderService, private messageService: MessageService) {}

  ngOnInit(): void {}

  async confirm(): Promise<void> {
    try {
      this.isLoading = true;

      this.assertConstraints();
      await this.authService.changePassword(this.currentPassword, this.newPassword);

      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Your password has been changed. Please log in again.'
      });

      await this.authService.logOut();
      location.reload();
    } catch (e: any) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: extractErrorText(e) || 'Your authentication information is incorrect. Please try again.'
      });
    } finally {
      this.isLoading = false;
    }
  }

  // Asserts that entered values are valid. Otherwise, throw an exception.
  assertConstraints(): void {
    // 1. Cannot be empty
    if (isEmpty(this.currentPassword) || isEmpty(this.newPassword) || isEmpty(this.confirmNewPassword)) {
      throw new Error('Input fields cannot be empty');
    }

    // 2. New passwords must match
    if (this.newPassword !== this.confirmNewPassword) {
      throw new Error("New passwords don't match");
    }

    // 3. Password must meet requirements
    if (!this.authService.passwordMeetsRequirements(this.newPassword)) {
      throw new Error('Password does not meet the requirements');
    }
  }
}

function isEmpty(str: any): boolean {
  return !str || str === '';
}
