import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import {
  AdministratorRole,
  AdvisorRole,
  BackofficeAdminRole,
  BackofficeRole,
  CompanyManagerRole,
  SiteAdminRole
} from 'src/app/-services-/auth-framework/api-constants/roles/role-instances';
import { AuthProviderService } from '../../-services-/auth-framework/auth-provider.service';
import { LogoSrcLoader } from './-services-/LogoSrcLoader';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WebSocketSubject } from 'rxjs/webSocket';
import { v4 as uuidv4 } from 'uuid';
import { getNotificationDetails } from './header-notification.utilities';
import { PortfolioRefreshService } from 'src/app/services/portfolio-stats-refresh.service';
import { CompactModeService } from 'src/app/compact-mode.service';
import { HttpClient } from '@angular/common/http';
import { NOTIF_TYPES } from './notification-list/notification.types';
import { RegistryRefreshService } from 'src/app/services/holder-registry-refresh.service';
import { CaViewer, TaxManger } from 'src/app/-services-/auth-framework/api-constants/applications/application-instances';
import { UnmappedDocCountRefreshService } from 'src/app/services/unmapped-doc-count-refresh.service';

export interface IMessage {
  type: NOTIF_TYPES;
  content?: Record<string, any>;
}

export interface INotification extends IMessage {
  notificationId: string;
  read: boolean;
  date: Date;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  logoSrcLoader: LogoSrcLoader;
  compactMode: boolean = false;
  private compactModeSubscription!: Subscription;
  notifications: INotification[] = [];
  webSocket: WebSocketSubject<any> | undefined;
  showNotificationsSideBar: boolean = false;

  constructor(
    private authService: AuthProviderService,
    private readonly msgService: MessageService,
    private readonly portfolioRefreshService: PortfolioRefreshService,
    private readonly registryRefreshService: RegistryRefreshService,
    private readonly unmappedDocCountRefreshService: UnmappedDocCountRefreshService,
    private compactModeService: CompactModeService,
    private http: HttpClient
  ) {
    this.logoSrcLoader = new LogoSrcLoader(this, authService);
  }

  get showNotice() {
    const isCaViewer = this.authService.verifyUserApplication(CaViewer);
    const isTaxManager = this.authService.verifyUserApplication(TaxManger);
    return isCaViewer && !isTaxManager;
  }

  logoSrc = '';

  silentNotificationTypes = [NOTIF_TYPES.HOLDER_DOWNLOAD, NOTIF_TYPES.UNMAPPED_DOC_COUNT];

  private verifiedUserRoles$ = this.authService.account$.pipe(
    map(() => ({
      isTaxManagerView: this.authService.verifyUserApplication(TaxManger),
      canViewActivities: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole].some((role) =>
        this.authService.verifyUserRole(role)
      ),
      canViewExternalData: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole].some((role) =>
        this.authService.verifyUserRole(role)
      ),
      canViewUserSecurityMgmt: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole].some((role) =>
        this.authService.verifyUserRole(role)
      ),
      canViewReports: [SiteAdminRole, AdministratorRole, CompanyManagerRole, BackofficeAdminRole, BackofficeRole, AdvisorRole].some((role) =>
        this.authService.verifyUserRole(role)
      )
    }))
  );

  toggleNotificationsSidebar = () => {
    this.showNotificationsSideBar = !this.showNotificationsSideBar;
  };

  dismissNotification(notificationId: any) {
    // Find the index of the notification with the given ID
    const index = this.notifications.findIndex((notification) => notification.notificationId === notificationId);
    if (index !== -1) {
      // Remove the notification from the array
      this.notifications.splice(index, 1);
    }
  }

  dismissAllNotifications() {
    this.notifications = [];
  }

  public items$: Observable<MenuItem[]> = this.verifiedUserRoles$.pipe(
    map(({ isTaxManagerView, canViewActivities, canViewExternalData, canViewUserSecurityMgmt, canViewReports }) => {
      return isTaxManagerView
        ? [
            {
              label: 'Home',
              icon: 'pi pi-fw pi-home',
              routerLink: '/portfolios'
            },
            {
              label: 'Activities',
              icon: 'pi pi-fw pi-calendar',
              routerLink: 'activities',
              visible: canViewActivities
            },
            {
              label: 'External data',
              icon: 'pi pi-fw pi-globe',
              routerLink: 'external-data',
              visible: canViewExternalData
            },
            {
              label: 'User Security',
              icon: 'pi pi-fw pi-id-card',
              routerLink: 'user-security-management',
              visible: canViewUserSecurityMgmt
            },
            {
              label: 'Reports',
              icon: 'pi pi-fw pi-chart-pie',
              routerLink: 'reports',
              visible: canViewReports
            }
          ]
        : [
            {
              label: 'Home',
              icon: 'pi pi-fw pi-home',
              routerLink: '/'
            }
          ];
    })
  );

  authenticatedSubscription!: Subscription;

  ngOnInit(): void {
    this.compactModeSubscription = this.compactModeService.getCompactMode().subscribe((compactMode) => {
      this.compactMode = compactMode;
    });
    this.logoSrcLoader.setUp();
    this.authenticatedSubscription = this.authService.isAuthenticated().subscribe((isLoggedIn) => {
      //this.msgService.add({detail: "Logged in event recvd"});
      const token = this.authService.getAccessToken();
      if (isLoggedIn && token) {
        this.webSocket?.complete();
        const WS_URL = environment.envVar.API_BASE_WS_URL;
        this.webSocket = new WebSocketSubject(`${WS_URL}?token=${token}`);
        //this.msgService.add({detail: "New websocket established"});
        this.webSocket.subscribe((message: IMessage) => {
          this.handleNotification(message);
        });
      } else {
        this.showNotificationsSideBar = false;
        this.webSocket?.complete();
      }
    });
  }

  handleNotification = (message: IMessage) => {
    const notificationType = message.type;
    // Only allow non silent notifications to be updated in array
    if (!this.silentNotificationTypes.includes(notificationType)) {
      this.notifications = [
        ...this.notifications,
        {
          notificationId: uuidv4(),
          read: false,
          ...message,
          date: new Date()
        } as INotification
      ];
    }

    // send this to a refresh service
    if (notificationType === NOTIF_TYPES.PORTFOLIO_PROCESSING) {
      this.portfolioRefreshService.sendMessage(message.content);
    }

    if (notificationType === NOTIF_TYPES.HOLDER_DOWNLOAD) {
      this.registryRefreshService.sendMessage(message.content?.status);
    }
    if (notificationType === NOTIF_TYPES.UNMAPPED_DOC_COUNT) {
      this.unmappedDocCountRefreshService.sendMessage(message.content?.unmappedDocCount || 0);
    }
    // Handle additional toast's for notification
    this.showToastMsg(message);
  };

  ngOnDestroy() {
    this.compactModeSubscription?.unsubscribe();
    this.logoSrcLoader?.tearDown();
    this.webSocket?.complete();
    this.authenticatedSubscription?.unsubscribe();
  }

  showToastMsg = (message: IMessage) => {
    const { showNotification, notificationObj, url } = getNotificationDetails(message);
    if (showNotification) {
      this.msgService.add(notificationObj);
    }
    if (url) {
      this.downloadExcelFile(url);
    }
  };

  downloadExcelFile(downloadUrl: string) {
    const urlHost = downloadUrl?.split('?')?.[0] || '';
    const fileNameInUrl = urlHost?.split('/').pop() || '';
    const fileName = decodeURIComponent(fileNameInUrl);
    const defaultName = `Report_${Date.now()}`;
    this.http.get(downloadUrl, { responseType: 'blob' }).subscribe((data: Blob) => {
      const url = window.URL.createObjectURL(data);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.setAttribute('download', fileName || defaultName);
      anchor.style.display = 'none';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    });
  }

  setCompactMode() {
    this.compactModeService.setCompactMode(this.compactMode);
  }

  /**
   * This controls whether the whole component should be displayed at all.
   */
  get shouldDisplay(): boolean {
    return this.authService.isLoggedIn && !location.pathname.endsWith('resetPassword');
  }
}
