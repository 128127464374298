import { Component, OnInit } from '@angular/core';
import { InvalidCredentialsError } from '../../../../-services-/auth-framework/auth-errors';
import { AuthProviderService } from '../../../../-services-/auth-framework/auth-provider.service';
import { LoginPageService } from '../../login-page.service';
import { extractErrorText } from '../../../../utilities/extract-error-text';

@Component({
  selector: 'app-mfa-form',
  templateUrl: './mfa-form.component.html',
  styleUrls: ['./mfa-form.component.css']
})
export class MfaFormComponent implements OnInit {
  invalid = false;
  errorMessage!: string;
  loading = false;

  inputValue!: string;

  constructor(private authProvider: AuthProviderService, public loginPageService: LoginPageService) {}

  ngOnInit(): void {}

  async onConfirm(): Promise<void> {
    try {
      this.loading = true;
      this.invalid = false;

      this.loginPageService.mfaToken = this.inputValue;
      await this.authProvider.logIn(
        this.loginPageService.username,
        this.loginPageService.password,
        this.loginPageService.acceptToS,
        this.loginPageService.mfaToken
      );
      this.loginPageService.loginSuccess.emit();
    } catch (e) {
      this.loading = false;
      this.invalid = true;

      if (e instanceof InvalidCredentialsError) {
        this.errorMessage = `That doesn't look right: ${extractErrorText(e)}`;
      } else {
        this.errorMessage = `Sorry, an error occurred. ${extractErrorText(e)}`;
        console.error(e);
      }
      console.error(e);
    }
  }
}
