import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CaViewer, TaxManger } from 'src/app/-services-/auth-framework/api-constants/applications/application-instances';
import { eSupportRole } from '../../../-services-/auth-framework/api-constants/roles/role-instances';
import { showErrorWithSwal } from '../../../common/util/swal-mixins/swal-error';
import { SwalSuccess } from '../../../common/util/swal-mixins/swal-success';
import { ApiHttpRequest } from '../../../../api-connector/api-http-request';

@Component({
  selector: 'app-create-e-support',
  templateUrl: './create-e-support.component.html',
  styleUrls: ['./create-e-support.component.css']
})
export class CreateESupportComponent implements OnInit {
  formGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.email, Validators.required]),
    password: new UntypedFormControl('', [Validators.required])
  });

  isLoading = false;

  constructor() {}

  ngOnInit(): void {}

  async confirm() {
    try {
      this.isLoading = true;
      await ApiHttpRequest.createEsupportOrSiteAdminAccount({
        accountType: 'Individual',
        organizationName: '',
        userEmail: this.formGroup.value.email as string,
        allowedUsersNumber: 1,
        masterEstmApplicationIds: [TaxManger.id, CaViewer.id],
        userRoleIds: [eSupportRole.apiInteger]
      });

      await SwalSuccess.fire('You have successfully created an e-support user.');
      this.formGroup.reset();
    } catch (e: any) {
      await showErrorWithSwal(e);
    } finally {
      this.isLoading = false;
    }
  }
}
